(function(wnd){
    function HeaderTextChanger(params) {
        try {
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    HeaderTextChanger.prototype = {
        texts:[],
        images:[],
        currentIndex:0,
        container: undefined,
        backgroundContainer: undefined,
        started: false,
        resetCounter: 0,
        options: {
            changeTime:4500,
            animationTime: 800,
            imageAnimationTime: 800,
            resetLimit:0,
            startTrigger: 'scroll',
            waitBeforeStart: 3000
        },
        init: function() {
            this.setElements();
            
            if (this.texts.length > 1 && this.container && this.container.length > 0) {
                this.addDOMEvents();
            }
        },
        addDOMEvents: function() {
            var _self = this;
            var startTrigger = this.getOption('startTrigger');
            switch (startTrigger) {
                case 'scroll':
                    var scrollFn = function() {
                        if (!_self.started) {
                            _self.started = true;
                            _self.showNextText();
                        }
                    };
                    
                    window.addEventListener('scroll', scrollFn);
                    break;
                default:
                    var wait = this.getOption('waitBeforeStart');
                    $(window).on('load', function() {
                        setTimeout(function(){
                            _self.started = true;
                            _self.showNextText();
                        }, wait);
                    });
                    break;
            }

            
        },
        showNextText: function() {
            var _self = this;

            this.currentIndex++;
            if (this.currentIndex == this.texts.length) {
                this.currentIndex = 0;
                this.resetCounter++;
            }

            var resetLimit = parseInt(this.getOption('resetLimit'));
            if (!resetLimit || (resetLimit && resetLimit > this.resetCounter)) {
                var animationTime = parseInt(this.getOption('animationTime'));
                var changeTime = parseInt(this.getOption('changeTime'));
                var imageAnimationTime = parseInt(this.getOption('imageAnimationTime'));

                this.container.animate({
                    opacity: 0
                }, animationTime, function() {
                    var text = _self.texts[_self.currentIndex];
                    _self.container.html(text);

                    _self.container.animate({
                        opacity:1
                    }, animationTime, function() {
                        setTimeout(function() {
                            _self.showNextText();
                        }, changeTime);
                    });
                });

                
                var currentBackground = this.backgroundContainer.find('.background-item');
                currentBackground.animate({
                    opacity:0
                }, imageAnimationTime, function() {
                    
                });

                var nextImage = _self.images[_self.currentIndex];
                var nextBackground = $('<div>', {
                    class:'background-item',
                    style:"background-image:url(\""+nextImage+"\");opacity:0;"
                });

                this.backgroundContainer.append(nextBackground);
                nextBackground.animate({
                    opacity:1
                }, imageAnimationTime, function(){
                    currentBackground.remove();
                });
            }
        },
        setElements: function() {
            this.container = $('body').find('[data-purpose="dynamic-header-text"]');
            this.backgroundContainer = $('body').find('[data-purpose="dynamic-header-background-container"]');
        },
        setParams: function(params) {
            if (params.texts) this.setTexts(params.texts);
            if (params.images) this.setImages(params.images);
            if (params.options) this.setOptions(params.options);
        },
        setTexts: function(texts) {
            if (typeof texts === 'object' && Array.isArray(texts)) {
                this.texts = texts;
            }
        },
        setImages: function(images) {
            if (typeof images === 'object' && Array.isArray(images)) {
                this.images = images;
            }
        },
        setOptions: function(options) {
            this.options = $.extend({}, this.options, options);
        },
        getOption: function(key) {
            if (typeof this.options[key] === 'undefined') {
                throw 'Option ('+key+') is undefined!';
            }

            return this.options[key];
        }
    };

    wnd.HeaderTextChanger = HeaderTextChanger;
})(window);
